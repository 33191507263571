<template>
  <div>
    <el-row class="app">
      <el-col>
        <div class="page-container">
          <div class="examInfoHead">
            <span class="examState">{{
              examInfo.examStatus | examStatus
            }}</span>
            <span class="examName ellipsis">{{ examInfo.examName }}</span>
            <el-button
              v-throttle
              size="medium"
              type="primary"
              @click="seeExamineeAchievementScan"
              >查看成绩总览</el-button
            >
          </div>
          <span class="span_inner">{{ examInfo.examTime }}</span>
          <span class="span_inner" style="margin-top: 8px">{{
            examInfo.subject
          }}</span>
        </div>
      </el-col>
    </el-row>
    <!-- 第二层 -->
    <div class="whiteBlock">
      <el-row style="margin-top: 20px; padding: 10px">
        <el-button
          v-throttle
          type="primary"
          icon="el-icon-download"
          size="medium"
          @click="showAchievementDialog"
          >下载成绩单</el-button
        >
        <el-button
          v-throttle
          type="primary"
          size="medium"
          @click="showBatchObjectionDialog"
          >批量提出异议</el-button
        >
        <span v-show="this.tableSelectionDataList.length > 0"
          >已选学生：{{ this.tableSelectionDataList.length }}</span
        >
      </el-row>
      <el-dialog
        class="examAchievementDynOptDialog"
        :visible.sync="downloadAchievementDialogVisible"
        :close-on-click-modal="false"
        width="26%"
        title="成绩下载模版配置"
      >
        <div class="achievementTemplate">
          <div style="margin: 0px 0px 10px 0px">
            <span>可选字段</span>
            <span style="color: grey">
              (共{{ dynamicColOptions.length }}个)</span
            >
          </div>

          <div class="examAchievementDynOptBox">
            <el-checkbox
              class="examAchievementDynOptCheckBox"
              :indeterminate="isIndeterminate"
              v-model="dynColCheckAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="dynamicColListValue"
              @change="handleCheckedOptionChange"
            >
              <el-checkbox
                class="examAchievementDynOptCheckBox"
                v-for="dynamicCol in dynamicColOptions"
                :label="dynamicCol.value"
                :key="dynamicCol.value"
                >{{ dynamicCol.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="achievementTemplate">
            <div style="margin: 0px 0px 10px 0px">
              <span>数据范围</span>
            </div>
            <div class="examAchievementDynOptBox">
              <div class="examAchievementDynOptCheckBox">
                <el-radio v-model="dataRange" label="1">导出全部数据</el-radio>
                <el-radio v-model="dataRange" label="2">导出查询结果</el-radio>
              </div>
            </div>
          </div>
        </div>
        <div class="examAchievementDialogData"></div>
        <div class="examAchievementButton">
          <el-button v-throttle @click="hiddenAchievementDialog"
            >取消</el-button
          >
          <el-button v-throttle type="primary" @click="downloadAchievementCheck"
            >下载</el-button
          >
        </div>
      </el-dialog>
      <!-- 批量提出异议弹出框-->
      <el-dialog
        :visible.sync="batchObjectionDialogVisible"
        width="900px"
        :close-on-click-modal="false"
        title="批量提出异议"
        center
      >
        <h3>
          您一共选择了 {{ this.tableSelectionDataList.length }} 名学生提出异议
        </h3>
        <div class="examAchievementDialogData">
          <el-row>
            <!-- ,
            background: '#DFE6EC',-->
            <el-table
              stripe
              ref="objectionMultipleTable"
              :data="tableSelectionDataList"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                'text-align': 'center',
              }"
              :cell-style="{ 'text-align': 'center' }"
              border
            >
              <el-table-column
                align="center"
                prop="studentName"
                label="姓名"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="examineeCode"
                label="考号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="subjectType"
                label="考试科目"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.subjectType | subjectFilter
                }}</template>
              </el-table-column>
              <el-table-column label="操作" width="200" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button
                    v-throttle
                    size="small"
                    type="danger"
                    @click="cancelRowSelection(scope.row)"
                    >移除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
        <div class="batchObjectionButton">
          <el-button v-throttle @click="hiddenBatchObjectionDialog"
            >返回选择</el-button
          >
          <el-button v-throttle type="primary" @click="submitBatchObjection()"
            >立即提交</el-button
          >
        </div>
      </el-dialog>
      <el-row style="margin-top: 20px" class="inputColor">
        <el-form :inline="true" :model="condition">
          <el-form-item label="姓名" class="formItemBoxStyle">
            <el-input
              v-model="condition.studentName"
              @change="handleCurrentChange(1)"
            ></el-input>
          </el-form-item>

          <el-form-item label="考号" class="formItemBoxStyle">
            <el-input
              v-model="condition.examineeCode"
              @change="handleCurrentChange(1)"
            ></el-input>
          </el-form-item>

          <el-form-item label="区县" class="formItemBoxStyle">
            <el-select
              v-model="condition.sysOrgAreaId"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="(v, i) in this.sysOrgAreaConditionList"
                :key="i"
                :value="v.sysOrgAreaId"
                :label="v.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="学校" class="formItemBoxStyle">
            <el-select
              v-model="condition.sysOrgSchoolId"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="(v, i) in this.sysOrgSchoolConditionList"
                :key="i"
                :value="v.sysOrgSchoolId"
                :label="v.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="班级" class="formItemBoxStyle">
            <el-select
              v-model="condition.sysOrgSchoolClassId"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="(v, i) in this.sysOrgSchoolClassConditionList"
                :key="i"
                :value="v.sysOrgSchoolClassId"
                :label="v.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="科目" class="formItemBoxStyle">
            <el-select
              v-model="condition.subjectType"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="物理" :value="1"></el-option>
              <el-option label="生物" :value="2"></el-option>
              <el-option label="化学" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="考试场次" class="formItemBoxStyle">
            <el-cascader
              v-model="sessionInfoChangeList"
              placeholder="全部"
              ref="cascader"
              :options="examSessionInfoListCondition"
              @change="sessionInfoChange"
              class="placeholderColor"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="考生状态" class="formItemBoxStyle">
            <el-select
              v-model="condition.examineeExamStatus"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="正常" :value="3"></el-option>
              <el-option label="缺考" :value="4"></el-option>
              <el-option label="取消考试资格" :value="5"></el-option>
              <el-option label="补考" :value="6"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="成绩状态" class="formItemBoxStyle">
            <el-select
              v-model="condition.achievementStatus"
              placeholder="全部"
              @change="handleCurrentChange(1)"
              class="placeholderColor"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异议" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="成绩" class="formItemBoxStyle">
            <el-input
              v-model="condition.startSection"
              placeholder
              style="width: 100px"
              @input="
                condition.startSection = formatDecimal(
                  condition.startSection,
                  1
                )
              "
              @change="handleCurrentChange(1)"
            ></el-input>
            <span style="display: inline-block; padding: 0 10px">至</span>
            <el-input
              v-model="condition.endSection"
              placeholder
              style="width: 100px"
              @input="
                condition.endSection = formatDecimal(condition.endSection, 2)
              "
              @change="handleCurrentChange(1)"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button v-throttle type="primary" @click="handleCurrentChange"
              >查询</el-button
            >
            <el-button v-throttle @click="flash">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row>
        <!-- , background: '#DFE6EC' -->
        <el-table
          ref="multipleTable"
          :data="studentAchievementInfoList"
          :row-key="getRowKey"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          border
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            :selectable="objectionSelectEnable"
            width="50"
          ></el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            prop="studentName"
            label="姓名"
            show-overflow-tooltip
            width="90"
          ></el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            prop="examineeCode"
            label="考号"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="orgSysOrgAreaName"
            label="区县"
            show-overflow-tooltip
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="orgSysOrgSchoolName"
            label="学校"
            width="160"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="orgSysOrgSchoolClassName"
            label="班级"
            width="160"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="subjectType"
            label="考试科目"
            show-overflow-tooltip
            width="80"
          >
            <template slot-scope="scope">{{
              scope.row.subjectType | subjectFilter
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="examSession"
            label="场次"
            width="250"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="score"
            label="成绩"
            show-overflow-tooltip
            width="70"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="examineeExamStatus"
            label="考试状态"
            width="60"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.examineeExamStatus | examineeExamStatusFilter
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="achievementStatus"
            label="成绩状态"
            show-overflow-tooltip
            width="60"
          >
            <template slot-scope="scope">{{
              scope.row.achievementStatus | achievementStatusFilter
            }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="examExamineeFaceAuthResultDTO.authResult"
            label="考生登录方式"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.examExamineeFaceAuthResultDTO.authResult
                  | examineeLoginTypeFilter
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="examExamineeFaceAuthResultDTO.authResult"
            label="刷脸登录状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.examExamineeFaceAuthResultDTO.authResult
                  | examineeLoginStatusFilter
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="examExamineeFaceAuthResultDTO.studentFaceImgUrl"
            label="刷脸图片"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                v-throttle
                size="mini"
                type="primary"
                icon="el-icon-view"
                v-show="
                  scope.row.examExamineeFaceAuthResultDTO.studentFaceImgUrl !=
                  null
                "
                @click="scanFaceImg(scope.row)"
                >查看图片</el-button
              >
              <span
                v-show="
                  scope.row.examExamineeFaceAuthResultDTO.studentFaceImgUrl ==
                  null
                "
                >暂无</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                v-throttle
                v-if="scope.row.examineeExamStatus != 5"
                size="small"
                type="primary"
                @click="check(scope.row)"
                >查看</el-button
              >
              <el-button
                v-throttle
                v-if="
                  scope.row.examineeExamStatus != 5 &&
                  scope.row.confusionStatus != true
                "
                size="small"
                type="primary"
                @click="raiseObjection(scope.row)"
                >提出异议</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 提出异议弹窗 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="750px"
        :close-on-click-modal="false"
        title="成绩异议"
      >
        <div class="row">
          <span class="rowTitle">姓名</span>
          <span class="rowText">{{ this.saveConfusionParam.studentName }}</span>
        </div>
        <div class="row">
          <span class="rowTitle">考号</span>
          <span class="rowText">
            {{ this.saveConfusionParam.examineeCode }}
          </span>
        </div>
        <div class="row">
          <span class="rowTitle">学校</span>
          <span class="rowText">
            {{ this.saveConfusionParam.orgSysOrgSchoolName }}
          </span>
        </div>
        <div class="row">
          <span class="rowTitle" style="margin-left: 20px">申请理由</span>
          <el-input
            type="textarea"
            v-model="description"
            placeholder
            maxlength="255"
          ></el-input>
        </div>
        <div class="btnFamily">
          <el-button v-throttle type="primary" @click="saveConfusion"
            >确认</el-button
          >
          <el-button
            v-throttle
            type="danger"
            style="margin-left: 30px"
            @click="closeObjection"
            >取消</el-button
          >
        </div>
      </el-dialog>
      <!-- 明细列表分页 -->
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.pageTotal"
        ></el-pagination>
      </el-row>
      <!-- 学生人脸图片预览弹框 -->
      <el-image-viewer
        v-if="previewStudentImgFaceShow"
        :on-close="() => (this.previewStudentImgFaceShow = false)"
        :url-list="this.studentFaceImgList"
      ></el-image-viewer>
    </div>
  </div>
</template>
<script>
import { setStore, getStore, removeStore } from '@/utils/token.js'
import { Loading } from 'element-ui'
import {
  queryExamInfo,
  queryAchievementDetailCondition,
  queryAchievementDetail,
  downloadAchievementUrl,
  saveConfusionApi,
  checkConfusionApi,
} from '@/api/score/scoreQuery.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import template from '../rollCall/template.vue'
const dynamicColList = [
  { value: 1, label: '姓名' },
  { value: 2, label: '学号' },
  { value: 3, label: '考号' },
  { value: 4, label: '区县' },
  { value: 5, label: '学校' },
  { value: 6, label: '班级' },
  { value: 7, label: '科目' },
  { value: 8, label: '考试名称' },
  { value: 9, label: '批次' },
  { value: 10, label: '考试题目' },
  { value: 11, label: '总分' },
  { value: 12, label: '签名(空白)' },
  { value: 13, label: '备注(空白)' },
]
export default {
  components: { template },
  components: { ElImageViewer },
  name: 'examDetailed',
  data() {
    return {
      examId: '',
      subjectTypeOne: '',
      subjectTypeList: [],
      examInfo: {
        examName: '',
        examTime: '',
        examSubject: '',
        examStatus: 2,
      },
      //学生成绩列表
      studentAchievementInfoList: [],
      //学生人脸图片信息
      studentFaceImgList: [],
      previewStudentImgFaceShow: false,
      //过滤参数
      condition: {
        studentName: '',
        examineeCode: '',
        sysOrgAreaId: '',
        sysOrgSchoolId: '',
        sysOrgSchoolClassId: '',
        subjectType: '',
        examineeExamStatus: '',
        achievementStatus: '',
        startSection: '',
        endSection: '',
        sysOrgExamPlaceId: '',
        sysOrgExamPlaceRoomId: '',
        examSession: '',
      },
      //场次过滤条件
      examSessionInfoListCondition: [
        {
          value: '',
          label: '',
          children: [
            {
              value: '',
              label: '',
              children: [
                {
                  value: '',
                  label: '',
                },
              ],
            },
          ],
        },
      ],
      //过滤条件
      sysOrgAreaConditionList: [],
      sysOrgSchoolConditionList: [],
      sysOrgSchoolClassConditionList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      sheetPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      ruleForm: {
        name: '',
      },
      dataRange: '1',
      description: '',
      dialogVisible: false,
      saveConfusionParam: {},
      saveConfusionParamList: [],
      downloadAchievementDialogVisible: false,
      batchObjectionDialogVisible: false,
      examineeAchievementInfoList: [],
      tableSelectionDataList: [],
      dynColCheckAll: true,
      dynamicColListValue: [],
      dynamicColOptions: dynamicColList,
      isIndeterminate: false,

      //
      sessionInfoChangeList: [],
    }
  },
  async mounted() {
    this.initExamId()
    await this.queryExamInfo()
    await this.getAchievementDetailCondition()
    if (getStore('examDetailedCondition')) {
      this.condition = JSON.parse(getStore('examDetailedCondition'))
      this.sessionInfoChangeList = [
        this.condition.sysOrgExamPlaceId,
        this.condition.sysOrgExamPlaceRoomId,
        this.condition.examSession,
      ]
      this.page = JSON.parse(getStore('page'))
    }
    await this.sendAsk()
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')
  },
  beforeCreate() {},
  beforeDestroy() {
    if (this.$route.path !== '/examManage/scoreDetails') {
      removeStore('examDetailedCondition')
      removeStore('page')
    }
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    initExamId() {
      this.examId = Number(this.$route.query.examId)
      //将字符串数组转换成整型数组
      if (this.$route.query.subject instanceof Object) {
        this.subjectTypeList = this.turnNum(this.$route.query.subject)
      } else {
        this.subjectTypeList = this.turnNum([this.$route.query.subject])
      }
    },
    //将字符串数组转换成整型数组
    turnNum(list) {
      return list.map(Number)
    },
    //选中列
    handleSelectionChange(val) {
      this.tableSelectionDataList = val
    },
    //取消选中列
    cancelRowSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.sendAsk()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      console.log('改变每页显示的数量:', val)
      this.page.pageSize = val
      this.sendAsk()
    },
    //点击提出异议按钮-弹出异议弹窗
    raiseObjection(row) {
      this.description = ''
      this.saveConfusionParam = {}
      this.saveConfusionParam = row
      this.saveConfusionParamList = []
      this.saveConfusionParamList.push(
        this.saveConfusionParam.achievementSummaryId
      )
      this.dialogVisible = true
    },
    //批量提出学生异议
    submitBatchObjection() {
      if (this.tableSelectionDataList.length <= 0) {
        this.$message.warning('请先选择学生！')
        return
      }
      let achievementSummaryIds = []
      this.tableSelectionDataList.forEach((v) => {
        achievementSummaryIds.push(v.achievementSummaryId)
      })
      let json = {
        achievementSummaryIds: achievementSummaryIds,
        examId: this.examId,
        reason: this.description,
      }
      checkConfusionApi(json).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            //存在学生已提交异议
            let existObjectionStudentList = []
            res.data.forEach((item) => {
              this.tableSelectionDataList.forEach((v) => {
                if (item == v.achievementSummaryId) {
                  existObjectionStudentList.push(v)
                  //删除选中内容
                  this.cancelRowSelection(v)
                }
              })
            })
            let tipString = ''
            for (
              let i = 0;
              i < existObjectionStudentList.length && i < 2;
              i++
            ) {
              tipString += existObjectionStudentList[i].studentName + `、`
            }
            tipString = tipString.substring(0, tipString.length - 1)
            if (existObjectionStudentList.length > 2) {
              tipString += ` 等考生已存在成绩异议`
            } else {
              tipString += ` 考生已存在成绩异议`
            }
            this.$message(tipString)
          } else {
            //不存在学生已提交异议
            this.$confirm('是否进行批量异议提交?', '提示', {
              confirmButtonText: '立即提交',
              cancelButtonText: '取消提交',
              type: 'warning',
              center: true,
            })
              .then(() => {
                this.saveConfusionParamList = []
                this.tableSelectionDataList.forEach((v) => {
                  this.saveConfusionParamList.push(v.achievementSummaryId)
                })
                this.saveConfusion()
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消提交',
                })
              })
          }
        }
      })
    },
    objectionSelectEnable(row) {
      if (row.confusionStatus == true || row.examineeExamStatus == 5) {
        return false
      } else {
        return true
      }
    },
    //异议弹窗-取消按钮
    closeObjection() {
      this.dialogVisible = false
    },
    //查看按钮-路由跳转
    check(row) {
      // 存信息
      setStore('examDetailedCondition', JSON.stringify(this.condition))
      setStore('page', JSON.stringify(this.page))
      // 跳路由
      this.$router.push({
        path: '/examManage/scoreDetails',
        query: {
          achievementSummaryId: row.achievementSummaryId,
          examId: this.examId,
          subject: this.subjectTypeList,
        },
      })
    },
    //查看成绩总览-路由跳转
    seeExamineeAchievementScan() {
      this.$router.push({
        name: 'scoreInfo',
        query: { examId: this.examId, subject: this.subjectTypeList },
      })
    },
    //处理学科数组方法
    subjectListFilter(list) {
      let str = ''
      for (let i = 0; i < list.length; i++) {
        switch (list[i]) {
          case 1:
            str += ` 物理`
            break
          case 2:
            str += ` 生物`
            break
          case 3:
            str += ` 化学`
            break
        }
      }
      return str
    },
    //处理时间方法
    dateFormat(val) {
      if (val > 0) {
        const dt = new Date(val)
        const y = dt.getFullYear()
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')
        //padStart(2,'0') 意思是不足两位自动补0
        const hh = (dt.getHours() + '').padStart(2, '0')
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        const ss = (dt.getSeconds() + '').padStart(2, '0')
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
      }
      return `未结束`
    },
    //获取考试信息
    async queryExamInfo() {
      await queryExamInfo(this.examId).then((res) => {
        let { success, data } = res
        if (success) {
          this.examInfo.examName = data.examName
          this.examInfo.examStatus = data.examStatus
          //过滤时间戳并且拼接
          let startTime = this.dateFormat(data.examStartTime)
          let endTime = this.dateFormat(data.examEndTime)
          this.examInfo.examTime = `${startTime} —— ${endTime}`
          //处理学科
          this.subjectTypeOne = data.subjectTypeList[0]
          this.examInfo.subject = this.subjectListFilter(data.subjectTypeList)
        }
      })
    },
    async getAchievementDetailCondition() {
      await queryAchievementDetailCondition(this.examId).then((res) => {
        if (res.success) {
          this.sysOrgAreaConditionList = res.data.sysOrgAreaList
          this.sysOrgSchoolConditionList = res.data.sysOrgSchoolList
          this.sysOrgSchoolClassConditionList = res.data.sysOrgSchoolClassList
          this.examSessionInfoListCondition = res.data.examSessionInfoList
          console.log('获取区县过滤条件', this.sysOrgAreaConditionList)
          console.log('获取学校过滤条件', this.sysOrgSchoolConditionList)
          console.log('获取班级过滤条件', this.sysOrgSchoolClassConditionList)
          console.log('获取批次过滤条件', this.examSessionInfoListCondition)
        }
      })
    },
    sessionInfoChange(nodeList) {
      this.condition.sysOrgExamPlaceId = nodeList[0]
      this.condition.sysOrgExamPlaceRoomId = nodeList[1]
      this.condition.examSession = nodeList[2]
      this.handleCurrentChange(1)
      console.log(
        '学生场次信息：',
        this.condition.sysOrgExamPlaceId,
        this.condition.sysOrgExamPlaceRoomId,
        this.condition.examSession
      )
    },
    //刷新
    flash() {
      const _cascader = this.$refs.cascader
      if (_cascader) {
        _cascader.$refs.panel.checkedValue = []
        _cascader.$refs.panel.activePath = []
        _cascader.$refs.panel.syncActivePath()
      }
      this.condition = {}
      this.handleCurrentChange(1)
    },
    // 请求
    async sendAsk() {
      //处理请求参数
      for (let key in this.condition) {
        if (this.condition[key] == '' || this.condition[key] == undefined) {
          delete this.condition[key]
        }
      }
      console.log('发送请求的参数', this.condition)
      console.log(
        '第几页：',
        this.page.pageIndex,
        ' 每页数据量',
        this.page.pageSize
      )
      //发送请求
      await queryAchievementDetail(
        this.page.pageIndex,
        this.page.pageSize,
        this.examId,
        this.condition
      ).then((res) => {
        if (res.success) {
          this.studentAchievementInfoList = res.data
          this.page.pageTotal = res.total
          console.log('发起请求列表结果：', res)
          console.log('学生成绩列表数据：', this.studentAchievementInfoList)
        }
      })
    },
    //限制小数点
    formatDecimal(str, num) {
      //str当前输入值，num是想要保留的小数位数
      str = String(str)
      if (!str.trim()) {
        return str
      }
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      // 如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 === 0 && len2 !== '.') {
        str = str.substr(1, 1)
      }
      // 第一位不能是.
      if (len1 === '.') {
        str = ''
      }
      // 限制只能输入一个小数点
      if (str.indexOf('.') !== -1) {
        var str_ = str.substr(str.indexOf('.') + 1)
        if (str_.indexOf('.') !== -1) {
          str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
        }
      }
      // 正则替换
      str = str.replace(/[^\d^.]+/g, '') // 保留数字和小数点
      if (num || num === 0) {
        var rest = str.split('.')[1]
        if (rest && rest.length > num) {
          rest = rest.substr(0, num)
          str = str.split('.')[0] + '.' + rest
        }
      }
      return str
    },
    scanFaceImg(row) {
      //学生人脸图片
      let studentFaceImgUrl =
        row.examExamineeFaceAuthResultDTO.studentFaceImgUrl
      //学生人脸认证图片
      let authImgUrl = row.examExamineeFaceAuthResultDTO.authImgUrl
      this.studentFaceImgList = []
      if (authImgUrl != null) {
        this.studentFaceImgList.push(this.$imageAddress(authImgUrl))
      }
      if (studentFaceImgUrl != null) {
        this.studentFaceImgList.push(this.$imageAddress(studentFaceImgUrl))
      }
      this.previewStudentImgFaceShow = true
    },
    //条件下载
    downloadAchievementCondition() {
      if (this.dynamicColListValue.length == 0) {
        this.$message.warning('请配置导出模板!')
        return
      }
      let loadingInstance = Loading.service({
        text: '下载需要一定的时间，请耐心等待',
      })
      //动态列值和条件参数
      let achievementCondition = {
        studentName: this.condition.studentName,
        examineeCode: this.condition.examineeCode,
        sysOrgAreaId: this.condition.sysOrgAreaId,
        sysOrgSchoolId: this.condition.sysOrgSchoolId,
        sysOrgSchoolClassId: this.condition.sysOrgSchoolClassId,
        subjectType: this.condition.subjectType,
        examineeExamStatus: this.condition.examineeExamStatus,
        achievementStatus: this.condition.achievementStatus,
        startSection: this.condition.startSection,
        endSection: this.condition.endSection,
        sysOrgExamPlaceId: this.condition.sysOrgExamPlaceId,
        sysOrgExamPlaceRoomId: this.condition.sysOrgExamPlaceRoomId,
        examSession: this.condition.examSession,
        rowTypeList: this.dynamicColListValue,
      }
      for (let key in achievementCondition) {
        if (
          achievementCondition[key] == '' ||
          achievementCondition[key] == undefined
        ) {
          delete achievementCondition[key]
        }
      }
      downloadAchievementUrl(this.examId, achievementCondition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close()
        })
        if (res.success) {
          console.log('成绩表下载链接', res.data)
          this.$message.success('下载成功')
          window.open(this.$imageAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    //选择下载方式
    downloadAchievementCheck() {
      if (this.dataRange == '1') {
        console.log('触发全部下载')
        this.downloadAchievementAll()
      }
      if (this.dataRange == '2') {
        console.log('触发条件下载')
        this.downloadAchievementCondition()
      }
    },
    //全量下载
    downloadAchievementAll() {
      if (this.dynamicColListValue.length == 0) {
        this.$message.warning('请设置导出模板!')
        return
      }
      let achievementConditionToAll = {
        rowTypeList: this.dynamicColListValue,
      }
      let loadingInstance = Loading.service({
        text: '下载需要一定的时间，请耐心等待',
      })
      downloadAchievementUrl(this.examId, achievementConditionToAll).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close()
          })
          if (res.success) {
            console.log('成绩表下载链接', res.data)
            this.$message.success('下载成功')
            window.open(this.$imageAddress(res.data))
          } else {
            console.log(res.msg)
            this.$message.error('下载失败，原因：' + res.msg)
          }
        }
      )
    },
    //显示成绩下载框
    showAchievementDialog() {
      this.downloadAchievementDialogVisible = true
      if (this.dynamicColListValue.length == 0) {
        dynamicColList.forEach((v) => {
          this.dynamicColListValue.push(v.value)
        })
      }
    },
    //隐藏成绩下载框
    hiddenAchievementDialog() {
      this.downloadAchievementDialogVisible = false
      this.dynColCheckAll = true
      this.isIndeterminate = false
    },
    //显示批量异议框
    showBatchObjectionDialog() {
      if (this.tableSelectionDataList.length <= 0) {
        this.$message.warning('请先选择学生！')
        return
      }
      this.batchObjectionDialogVisible = true
    },
    //隐藏批量异议框
    hiddenBatchObjectionDialog() {
      this.batchObjectionDialogVisible = false
    },
    saveConfusion() {
      let json = {
        achievementSummaryIds: this.saveConfusionParamList,
        examId: this.examId,
        reason: this.description,
      }
      saveConfusionApi(json).then((res) => {
        if (res.success) {
          this.dialogVisible = false
          this.batchObjectionDialogVisible = false
          this.tableSelectionDataList.forEach((v) => {
            this.cancelRowSelection(v)
          })
          this.tableSelectionDataList = []
          this.msgSuccess('提交成功')
          this.handleCurrentChange(this.page.pageIndex)
        } else {
          if (res.code != '500') {
            this.msgError(res.msg)
          } else {
            this.msgError('提交失败')
          }
        }
      })
    },
    getRowKey(row) {
      return row.achievementSummaryId
    },
    handleCheckAllChange(val) {
      if (val) {
        dynamicColList.forEach((v) => {
          this.dynamicColListValue.push(v.value)
        })
      } else {
        this.dynamicColListValue = []
      }
      this.isIndeterminate = false
    },
    handleCheckedOptionChange(value) {
      let checkedCount = value.length
      this.dynColCheckAll = checkedCount === this.dynamicColOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.dynamicColOptions.length
    },
  },
  filters: {
    //学科过滤
    subjectFilter(val) {
      switch (val) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
      }
    },
    examineeExamStatusFilter(key) {
      switch (key) {
        case 3:
          return `正常`
        case 4:
          return `缺考`
        case 5:
          return `取消考试资格`
        case 6:
          return `补考`
        default:
          return `错误的值${key}`
      }
    },
    achievementStatusFilter(key) {
      switch (key) {
        case 1:
          return `正常`
        case 2:
          return `成绩异议`
        default:
          return `无`
      }
    },
    //考试状态过滤
    examStatus(val) {
      switch (val) {
        case 2:
          return `等待考试`
        case 3:
          return `正在考试`
        case 4:
          return `考试完成`
        case 5:
          return `等待补考`
        case 6:
          return `正在补考`
        case 7:
          return `考试终止`
      }
    },
    examineeLoginTypeFilter(val) {
      switch (val) {
        case 0:
          return `刷脸登录`
        case 1:
          return `刷脸登录`
        case 2:
          return `刷脸登录`
        case 4:
          return `刷脸登录`
        case 6:
          return `线下审核`
        case 7:
          return `刷脸登录`
      }
    },
    examineeLoginStatusFilter(val) {
      switch (val) {
        case 0:
          return `人脸识别失败，人工核验通过`
        case 1:
          return `人脸识别成功`
        case 2:
          return `人工核验通过`
        case 4:
          return `暂无`
        case 6:
          return `暂无`
        case 7:
          return `暂无`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
//去掉dialog弹窗右上角的关闭按钮
::v-deep .el-dialog__headerbtn {
  display: none;
}

.el-input {
  // width: 100px;
}

.el-select {
  // width: 100px;
}

.app {
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 0 4px 4px 0;
  border-left: 10px solid #2295ff;
  box-sizing: border-box;
  height: 139px;
  margin-top: 10px;
}

.page-container {
  border-left: 0px solid;
  margin-top: 20px;
}

.whiteBlock {
  background-color: #ffffff;
  width: 100%;
}

.page-container > span:first-child {
  font-size: 25px;
  position: relative;
  top: 3px;
}

.page-container > button {
  margin-left: 20px;
}

.span_inner {
  color: #909399;
  display: block;
}

.examState {
  width: 80px;
  height: 25px;
  display: inline-block;
  font-size: 15px !important;
  background-color: #2295ff;
  color: #ffffff;
  border-radius: 15px;
  line-height: 25px;
  text-align: center;
}

.examInfoHead {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.examName {
  font-size: 28px;
  color: #778ca2;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0 10px;
}

.row {
  margin-top: 30px;

  .rowTitle {
    font-size: 15px;
    color: #303133;
    font-weight: bold;
    margin-left: 50px;
  }

  .rowText {
    margin-left: 30px;
    font-size: 15px;
    color: #606266;
  }

  ::v-deep .el-textarea__inner {
    width: 82%;
    height: 250px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dfe6ec;
    margin-left: 110px;
    resize: none;
    margin-top: -10px;
  }
}

.btnFamily {
  margin-top: 5%;
  margin-left: 522px;
}

.inputColor ::v-deep input::-webkit-input-placeholder {
  color: #606266 !important;
}

.examAchievementButton {
  justify-content: center;
  display: flex;
}

.batchObjectionButton {
  justify-content: center;
  display: flex;
}

.achievementTemplate {
  width: auto;
}

.examAchievementDynOptBox {
  background-color: rgba(241, 243, 244, 1);
  margin: 0px 0px 10px 0px;
  overflow: auto;
}

.examAchievementDynOptCheckBox {
  margin: 10px 36px 10px 10px;
}
.examAchievementDynOptDialog {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
}
::v-deep .examAchievementDynOptDialog .el-dialog__header {
  padding: 10px 10px 10px;
}
::v-deep .examAchievementDynOptDialog .el-dialog__body {
  padding: 15px 15px 15px;
}
</style>
