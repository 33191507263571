<template>
  <div></div>
</template>
<script>
export default {
  name: 'template',
  data () {
    return {

    }
  },
  mounted () { },
  methods: {

  }
}
</script>
<style></style>